@import "../../common";

.Footer {

  @include max-w($mobile_lg) {
    border-top: 1px solid rgba( #133040, .1); } }

.Footer__Logo {

  @include max-w($mobile_lg) {
    width: 110px;
    margin-bottom: 10px; }

  img {
    max-width: 100%;
    height: auto; } }

.Footer__Container {
  max-width: 1304px;
  padding: 0 20px;
  margin: 0 auto;

  @include max-w($tablet) {
    padding: 0 30px; } }

.Footer__Inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-top: 1px solid rgba( #133040, .1);

  @include max-w($tablet) {
    padding: 28px 0; }

  @include max-w($mobile_lg) {
    flex-direction: column;
    padding: 16px 0;
    border: none; } }

.Footer__Copyright {
  font-family: $font_base;
  font-size: 12px;
  color: rgba(#133040, .5); }

.Footer__Container {
  max-width: 1240px; }
