@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), url(./Montserrat-Regular.ttf) format('truetype'); }


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), url(./Montserrat-Medium.ttf) format('truetype'); }


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), url(./Montserrat-SemiBold.ttf) format('truetype'); }


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), url('./montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), url(./Montserrat-Bold.ttf) format('truetype'); }
