*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  display: flex;
  flex-direction: column;
  table-layout: fixed;
  width: 100%;
  min-height: 100%;
  font-family: sans-serif;
  line-height: 1.15;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(#000, 0); }

body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  width: 100%;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  backface-visibility: hidden;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal; }

h1 {
  font-size: 48px;
  line-height: calc(58 / 48);
  font-weight: bold;
  color: #133040; }

h2 {
  font-size: 36px;
  line-height: calc(44 / 36);
  font-weight: bold;

  &::after {
    content: '';
    display: inline-block;
    width: 27px;
    height: 44px;
    background-image: url('./slash.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(10px);
    margin-left: 10px;

    @include max-w($tablet) {
      width: 18px;
      height: 24px;
      transform: translateY(4px); }

    @include max-w($mobile_lg) {
      width: 12px;
      height: 16px;
      transform: translateY(1px); } }

  @include max-w($tablet) {
    font-size: 24px; }

  @include max-w($mobile_lg) {
    font-size: 16px; } }

p {
  font-size: 20px;
  line-height: 28px;
  color: rgba(#133040, .6);
  font-weight: normal; }

.container {
  max-width: 1304px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;

  &--slim {
    max-width: 1240px; }

  @include max-w($tablet) {
    padding: 0 30px; }

  @include max-w($mobile_lg) {
    padding: 0 24px; } }
