@import "mixins";
@import "variables";
@import "base";
@import "palette";


.Title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 36px;
  color: palette('secondary');
  letter-spacing: 0; }

.relative-pos {
  position: relative; }

.TitleContainer {
  margin-top: 75px;
  margin-bottom: 75px;

  padding: 100px 120px; }
